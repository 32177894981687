import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

export default function CivilInfrastructure() {
  const isTablet = useMediaQuery({ query: "(min-width: 760px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  return (
    <section className="--container-4" data-scroll-section>
      <Container>
      {isTablet && (
        <Row className="justify-content-center" 
        style={{
          "margin-top": "50vw",
        }}
        > 
          <Col md={12}>
            <div>
              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  position: "relative",
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "40%",
                    right: "-55%",
                    width: "75%",
                  }}
                  src={
                    require("../../../assets/images/civilinfrastructure/350.svg")
                      .default
                  }
                />
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  PIPE JACKING WORK{" "}
                </Col>
                <Row>
                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Palm Grove Housing Sdn Bhd
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "wvw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM3 million
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Jul 1991 to May 1995
                    </Col>
                  </Col>
                </Row>

                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                }}
              >
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  PIPE JACKING FOR SG BULOH TO JOHOR <br />
                  BAHRU FOR WATER PRIVATIZATION @1500M <br />
                  DIA. & 1350 DIA{" "}
                </Col>
                <Row>
                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Active Review Sdn Bhd
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM12 million
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Oct 1994 to Nov 1994
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  position: "relative"
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "15vw",
                    right: "-55%",
                    width: "70vw",
                  }}
                  src={
                    require("../../../assets/images/civilinfrastructure/460.svg")
                      .default
                  }
                />

                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  PIPE JACKING ULU TIRAM, JOHOR BAHRU <br /> FOR WATER
                  PRIVATIZATION @ 1500MM DIA. <br /> & 1350MM DIA.
                </Col>
                <Row>
                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Active Review Sdn Bhd
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM10 million
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      May 1994 to Oct 1994
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw"
                }}
              >
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  KTM LINE RAILWAY N. SEMBILAN, <br />@ SIZE ID 1.5M & ID= 1.8M{" "}
                </Col>
                <Row>
                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Hong Leong Property
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM8.6 million
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Apr 1994 to Aug 1994
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  position: "relative"
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "15vw",
                    right: "-10%",
                    width: "30vw",
                  }}
                  src={
                    require("../../../assets/images/civilinfrastructure/svg-1.svg")
                      .default
                  }
                />
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  KTM LINE RAILWAY ALOR STAR,
                  <br />@ SIZE ID= 1.8M{" "}
                </Col>
                <Row>
                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Low Then & Sons Sdn Bhd
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM7.5 million
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      March 1994 to May 1994
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw"
                }}
              >
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  ASSOCIATED WORKS KTMB, NILAI, <br />
                  NEG. SEMBILAN @ 1.9M{" "}
                </Col>
                <Row>
                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Arab Malaysian Development Berhad
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM2 million
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Sept 1992 to Dec 1993
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  position: "relative"
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "45%",
                    right: "-55%",
                    width: "70vw",
                  }}
                  src={
                    require("../../../assets/images/civilinfrastructure/460.svg")
                      .default
                  }
                />

                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  SENAI HIGHWAY TWIN @ SIZE 1.8M DIA.{" "}
                </Col>
                <Row>
                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Bina Baik Sdn Bhd
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM6 million
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      March 1993 to Dec 1993
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="opacity-100" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw"
                }}
              >
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  LINE RAILWAY GASING INDAH PETALING{" "}
                </Col>
                <Row>
                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Golden Hope Berhad
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM4 million
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Oct 1992 to Jan 1995
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  position: "relative"
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "45%",
                    right: "-50%",
                    width: "65vw",
                  }}
                  src={
                    require("../../../assets/images/civilinfrastructure/350.svg")
                      .default
                  }
                />

                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  R.C. BRIDGE 50M SPAN LOT PT 60, <br />
                  MUKIM PAGOH, ALOR GAJAH MELAKA{" "}
                </Col>
                <Row>
                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      JKR Melaka
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM12 million
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      1996 to 1997
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw"
                }}
              >
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  DANGER AREA, ROAD WORKS CONTRACT <br />
                  NO. LKR/NS/P/R/104 @ KM32.1 – <br />
                  KM33.4 OF JLN SEREMBAN TAMPIN, <br />
                  REMBAU, N. SEMBILAN{" "}
                </Col>
                <Row>
                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      JKR Negeri Sembilan
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM14.8 million
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Feb 1994 to Nov 1994
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  position: "relative"
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "45%",
                    right: "-55%",
                    width: "70vw",
                  }}
                  src={
                    require("../../../assets/images/civilinfrastructure/460.svg")
                      .default
                  }
                />
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  COMPLETION OF SEWERAGE LINE- <br />
                  RANCANGAN JAYA SELATAN, PJS2-PKNS{" "}
                </Col>
                <Row>
                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      PKNS
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      —
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Aug 1992 to July 1993
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
              >
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  GRADE SEPARATED INTERCHANGE AND <br />
                  ASSOCIATE WORKS, TMN MOUNT AUSTIN, <br />
                  JOHOR BAHRU-KOTA TINGGI ROAD{" "}
                </Col>
                <Row>
                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      JKR Johor
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM13.42 million
                    </Col>
                  </Col>

                  <Col md={12}>
                    <Col
                      style={{
                        "font-size": "2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      1993 to 1995
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
            </div>
          </Col>
        </Row>
        )}
      {isMobile && (
        <Row className="justify-content-center"> 
          <Col md={8}>
            <div>
              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  position: "relative",
                  "margin-left": "3vw",
                  "margin-right": "3vw",
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "-43%",
                    width: "75%",
                  }}
                  src={
                    require("../../../assets/images/civilinfrastructure/350.svg")
                      .default
                  }
                />
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "3.2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  PIPE JACKING WORK{" "}
                </Col>
                <Row>
                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Palm Grove Housing Sdn Bhd
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM3 million
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Jul 1991 to May 1995
                    </Col>
                  </Col>
                </Row>

                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  "margin-left": "3vw",
                  "margin-right": "3vw",
                }}
              >
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "3.2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  PIPE JACKING FOR SG BULOH TO JOHOR <br />
                  BAHRU FOR WATER PRIVATIZATION @1500M <br />
                  DIA. & 1350 DIA{" "}
                </Col>
                <Row>
                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Active Review Sdn Bhd
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM12 million
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Oct 1994 to Nov 1994
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  position: "relative",
                  "margin-left": "3vw",
                  "margin-right": "3vw",
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "33vw",
                    right: "-65%",
                    width: "100vw",
                  }}
                  src={
                    require("../../../assets/images/civilinfrastructure/460.svg")
                      .default
                  }
                />

                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "3.2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  PIPE JACKING ULU TIRAM, JOHOR BAHRU <br /> FOR WATER
                  PRIVATIZATION @ 1500MM DIA. <br /> & 1350MM DIA.
                </Col>
                <Row>
                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Active Review Sdn Bhd
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM10 million
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      May 1994 to Oct 1994
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  "margin-left": "3vw",
                  "margin-right": "3vw",
                }}
              >
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "3.2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  KTM LINE RAILWAY N. SEMBILAN, <br />@ SIZE ID 1.5M & ID= 1.8M{" "}
                </Col>
                <Row>
                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Hong Leong Property
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM8.6 million
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Apr 1994 to Aug 1994
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  position: "relative",
                  "margin-left": "3vw",
                  "margin-right": "3vw",
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "25vw",
                    right: "-13%",
                    width: "39vw",
                  }}
                  src={
                    require("../../../assets/images/civilinfrastructure/svg-1.svg")
                      .default
                  }
                />
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "3.2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  KTM LINE RAILWAY ALOR STAR,
                  <br />@ SIZE ID= 1.8M{" "}
                </Col>
                <Row>
                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Low Then & Sons Sdn Bhd
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM7.5 million
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      March 1994 to May 1994
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  "margin-left": "3vw",
                  "margin-right": "3vw",
                }}
              >
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "3.2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  ASSOCIATED WORKS KTMB, NILAI, <br />
                  NEG. SEMBILAN @ 1.9M{" "}
                </Col>
                <Row>
                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Arab Malaysian Development Berhad
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM2 million
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Sept 1992 to Dec 1993
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  position: "relative",
                  "margin-left": "3vw",
                  "margin-right": "3vw",
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "53%",
                    right: "-65%",
                    width: "100vw",
                  }}
                  src={
                    require("../../../assets/images/civilinfrastructure/460.svg")
                      .default
                  }
                />

                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "3.2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  SENAI HIGHWAY TWIN @ SIZE 1.8M DIA.{" "}
                </Col>
                <Row>
                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Bina Baik Sdn Bhd
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM6 million
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      March 1993 to Dec 1993
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="opacity-100" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  "margin-left": "3vw",
                  "margin-right": "3vw",
                }}
              >
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "3.2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  LINE RAILWAY GASING INDAH PETALING{" "}
                </Col>
                <Row>
                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Golden Hope Berhad
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM4 million
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Oct 1992 to Jan 1995
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  position: "relative",
                  "margin-left": "3vw",
                  "margin-right": "3vw",
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "67%",
                    right: "-43%",
                    width: "75%",
                  }}
                  src={
                    require("../../../assets/images/civilinfrastructure/350.svg")
                      .default
                  }
                />

                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "3.2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  R.C. BRIDGE 50M SPAN LOT PT 60, <br />
                  MUKIM PAGOH, ALOR GAJAH MELAKA{" "}
                </Col>
                <Row>
                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      JKR Melaka
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM12 million
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      1996 to 1997
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  "margin-left": "3vw",
                  "margin-right": "3vw",
                }}
              >
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "3.2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  DANGER AREA, ROAD WORKS CONTRACT <br />
                  NO. LKR/NS/P/R/104 @ KM32.1 – <br />
                  KM33.4 OF JLN SEREMBAN TAMPIN, <br />
                  REMBAU, N. SEMBILAN{" "}
                </Col>
                <Row>
                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      JKR Negeri Sembilan
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM14.8 million
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Feb 1994 to Nov 1994
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{
                  "margin-bottom": "10vw",
                  position: "relative",
                  "margin-left": "3vw",
                  "margin-right": "3vw",
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "53%",
                    right: "-65%",
                    width: "100vw",
                  }}
                  src={
                    require("../../../assets/images/civilinfrastructure/460.svg")
                      .default
                  }
                />
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "3.2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  COMPLETION OF SEWERAGE LINE- <br />
                  RANCANGAN JAYA SELATAN, PJS2-PKNS{" "}
                </Col>
                <Row>
                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      PKNS
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      —
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      Aug 1992 to July 1993
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
              <hr className="--opacitycv" />

              <Row
                className="item"
                style={{ "margin-left": "3vw", "margin-right": "3vw" }}
              >
                <Col
                  md={12}
                  style={{
                    "margin-top": "5vw",
                    "font-size": "3.2vw",
                    "letter-spacing": "1px",
                    "line-height": "2",
                  }}
                >
                  {" "}
                  GRADE SEPARATED INTERCHANGE AND <br />
                  ASSOCIATE WORKS, TMN MOUNT AUSTIN, <br />
                  JOHOR BAHRU-KOTA TINGGI ROAD{" "}
                </Col>
                <Row>
                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Client
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      JKR Johor
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Value
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      RM13.42 million
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col
                      style={{
                        "font-size": "3.2vw",
                        "margin-top": "3vw",
                        "letter-spacing": ".1em",
                      }}
                    >
                      Period
                    </Col>

                    <Col
                      style={{ "font-size": "3.2vw", "margin-top": "3vw" }}
                      className="--light"
                    >
                      1993 to 1995
                    </Col>
                  </Col>
                </Row>
                <br />
                <br />
              </Row>
            </div>
          </Col>
        </Row>
        )}
      </Container>
      {/* <Image
        className="--right-1"
        src={
          require("../../../assets/images/civilinfrastructure/right-1.svg")
            .default
        }
      /> */}
      <Image
        className="--right-2"
        src={
          require("../../../assets/images/civilinfrastructure/right-2.png")
            .default
        }
      />

      <Image
        className="--right-4"
        src={
          require("../../../assets/images/civilinfrastructure/right-4.png")
            .default
        }
      />
      {/* <Image
        className="--right-5"
        src={
          require("../../../assets/images/civilinfrastructure/right-5.svg")
            .default
        }
      /> */}

      <Image
        className="--right-6 item pt-5"
        src={
          require("../../../assets/images/civilinfrastructure/977.svg").default
        }
      />
    </section>
  );
}
